import { Injectable } from '@angular/core';

import { BaseStore } from '@lib-core/stores';
import { AppModeEnum } from '@proj-customer/app/enums/app-mode.enum';

import { APP_MODE_COOKIE_NAME } from '@lib-core/constants';

@Injectable({
	providedIn: 'root',
})
export class AppModeStore extends BaseStore<AppModeEnum> {
	constructor() {
		super(APP_MODE_COOKIE_NAME, false);
	}

	isPortalB2B() {
		return this.get() === AppModeEnum.PortalB2B;
	}

	isPortalCustomer() {
		return this.get() === AppModeEnum.PortalCustomer;
	}
}
