import { CommonModule } from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { Component, EventEmitter, Input, Output, ViewChild, OnInit, effect } from '@angular/core';

import { CartStore } from '@lib-core/stores';
import { ColorSampleComponent } from '@proj-b2b/app/components/color-sample/color-sample.component';
import { ProductImageComponent } from '@proj-b2b/app/components/product-image/product-image.component';
import { ColorSampleListComponent } from '@proj-b2b/app/components/color-sample-list/color-sample-list.component';
import { CapitalizePipe, CurrencyBrlPipe } from '@lib-core/pipes';
import { CartModel, ColorModel, ImageModel, ProductModel } from '@lib-core/models';
import { leftArrowUnicode, rightArrowUnicode } from '@lib-core/constants/unicode-characters-contants';
import { ProductImageColor } from '@lib-core/enums/color.type';

@Component({
	standalone: true,
	selector: 'app-gallery-card',
	templateUrl: './gallery-card.component.html',
	styleUrls: ['./gallery-card.component.scss'],
	imports: [CommonModule, CapitalizePipe, CurrencyBrlPipe, NgbTooltipModule, ColorSampleComponent, ProductImageComponent, ColorSampleListComponent],
})
export class GalleryCardComponent implements OnInit {
	@Input({ required: true }) product: ProductModel;
	@Output() onAddClick = new EventEmitter<ProductModel>();
	@Output() onDetailsClick = new EventEmitter<ProductModel>();

	cart: CartModel;
	selectedColor: ColorModel;
	startColorIndex: number = 0;
	imageIndex: number = 0;
	navigatingByIndex: boolean = false;
	autoNextImageInterval: any;
	leftArrow = leftArrowUnicode;
	rightArrow = rightArrowUnicode;

	@ViewChild(ColorSampleComponent)
	colorSample: ColorSampleComponent;

	constructor(private cartStore: CartStore) {
		effect(() => {
			this.cart = this.cartStore.get();
		});
	}

	ngOnInit(): void {
		this.initializeFirstImage();
	}

	initializeFirstImage(): void {
		// Regra solicitada pela Malwee de não utilizar foto do produto branco como primeira imagem na galeria
		const firstImage = this.imageList[this.imageIndex];
		if (firstImage && firstImage.color === ProductImageColor.White) {
			const nextImageIndex = this.imageList.findIndex(image => image.color !== ProductImageColor.White);
			if (nextImageIndex !== -1) {
				this.imageIndex = nextImageIndex;
			}
		}
	}

	get imageList(): ImageModel[] {
		if (this.product?.imagesUrl && this.product.skus) {
			const filteredImages = this.product.imagesUrl.filter(image => this.product.skus.some(sku => sku.colorCode === image.color));
			return filteredImages;
		}
		return [];
	}

	get colorList() {
		return this.product?.colors?.slice(0, 5) ?? [];
	}

	get currentColorSection() {
		const colors = this.product.colors;
		const skus = this.product.skus;
		const availableColors = colors.filter(color => skus.some(sku => sku.colorCode === color.code));
		if (this.startColorIndex >= availableColors.length) {
			this.startColorIndex = Math.max(0, availableColors.length - 1);
		}
		return availableColors.slice(this.startColorIndex, this.startColorIndex + 5);
	}

	get filteredColors() {
		const colors = this.product.colors;
		const skus = this.product.skus;
		return colors.filter(color => skus.some(sku => sku.colorCode === color.code));
	}

	get skuList() {
		return this.product.skus ?? [];
	}

	get firstValidImageUrl() {
		return this.imageList.find(image => !!image.url)?.url;
	}

	get hasProductInCart() {
		return this.cart?.cartItemList.find(item => item.productCode === this.product.code) !== undefined;
	}

	get buttonLabel() {
		return this.hasProductInCart ? 'Editar' : 'Adicionar';
	}

	get buttonClass() {
		return this.hasProductInCart ? 'btn-success' : 'btn-outline-primary';
	}

	get buttonIcon() {
		return this.hasProductInCart ? 'bi bi-cart' : 'bi bi-plus-lg';
	}

	getImageUrl() {
		return this.imageList[this.imageIndex]?.url ?? this.firstValidImageUrl;
	}

	previousImage() {
		this.navigatingByIndex = true;
		this.imageIndex = (this.imageIndex - 1 + this.imageList.length) % this.imageList.length;
	}

	nextImage() {
		this.navigatingByIndex = true;
		this.imageIndex = (this.imageIndex + 1) % this.imageList.length;
	}

	onColorChange(selectedColor: ColorModel) {
		if (this.selectedColor?.code === selectedColor.code) {
			return;
		}
		this.navigatingByIndex = false;
		this.selectedColor = selectedColor;
		this.imageIndex = this.imageList.findIndex(image => image.color === selectedColor.code);
		if (this.imageIndex === -1) {
			this.imageIndex = 0;
		}
	}

	getPriceRanges() {
		return this.skuList.map(sku => sku.price);
	}

	onAddProductToCard() {
		this.onAddClick.emit(this.product);
	}

	onDetailsProductToCard() {
		this.onDetailsClick.emit(this.product);
	}

	onColorDeselected() {
		this.colorSample.resetSelection();
	}

	nextColorSection() {
		this.startColorIndex = Math.min(this.startColorIndex + 5, this.product.colors.length - 5);
	}

	previousColorSection() {
		this.startColorIndex = Math.max(this.startColorIndex - 5, 0);
	}

	startAutoNextImage(): void {
		this.stopAutoNextImage();
		this.autoNextImageInterval = setInterval(() => {
			this.nextImage();
		}, 1500);
	}

	stopAutoNextImage(): void {
		if (this.autoNextImageInterval) {
			clearInterval(this.autoNextImageInterval);
			this.autoNextImageInterval = null;
		}
	}

	getTotalStock() {
		return this.product.skus[0].totalStockAsString;
	}
}
