import { Routes } from '@angular/router';
import { AuthGuard } from '@lib-auth/auth';
import { CartResolver, DiscountsResolver } from '@lib-core/resolvers';
import { ProductDetailsComponent } from '@proj-b2b/app/modules/product/product-details/product-details.component';
import { ProductWrapperComponent } from '@proj-b2b/app/modules/product/product-wrapper.component';

import { PRODUCT_PATH, PRODUCT_ROUTE_NAMES } from '@proj-b2b/app/modules/product/product.constants';

export const PRODUCT_ROUTE_LIST: Routes = [
	{
		path: PRODUCT_PATH,
		component: ProductWrapperComponent,
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: PRODUCT_ROUTE_NAMES.detail.path,
			},
			{
				path: PRODUCT_ROUTE_NAMES.detail.path,
				title: PRODUCT_ROUTE_NAMES.detail.title,
				component: ProductDetailsComponent,
				canActivate: [AuthGuard],
				resolve: { CartResolver, DiscountsResolver },
			},
		],
	},
];
