import { Injectable } from '@angular/core';
import { GalleryResponseDto } from '@lib-core/dtos';
import { GALLERY_DATA_COOKIE_NAME } from '@lib-core/constants';
import { BaseStore, SideFilterStore } from '@lib-core/stores';

@Injectable({
	providedIn: 'root',
})
export class GalleryResponseStore extends BaseStore<GalleryResponseDto> {
	constructor(private sideFilterStore: SideFilterStore) {
		super(GALLERY_DATA_COOKIE_NAME);
	}

	override set(value: GalleryResponseDto): void {
		super.set(value);
		this.sideFilterStore.set(value?.sideFilters);
	}
}
