import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HeroComponent } from '@proj-b2b/app/components/hero/hero.component';
import { NavbarComponent } from '@lib-shared/components';
import { GalleryContentComponent } from '@proj-b2b/app/modules/gallery/components/gallery-content/gallery-content.component';

@Component({
	standalone: true,
	selector: 'app-gallery-wrapper',
	imports: [CommonModule, RouterModule, NavbarComponent, HeroComponent, GalleryContentComponent],
	templateUrl: './gallery-wrapper.component.html',
})
export class GalleryWrapperComponent {}
