import { Routes } from '@angular/router';
import { AuthGuard } from '@lib-auth/auth';
import { CartResolver, DiscountsResolver } from '@lib-core/resolvers';

import { GalleryListComponent } from '@proj-b2b/app/modules/gallery/gallery-list/gallery-list.component';
import { GalleryWrapperComponent } from '@proj-b2b/app/modules/gallery/gallery-wrapper.component';
import { GALLERY_PATH, GALLERY_ROUTE_NAMES } from '@proj-b2b/app/modules/gallery/gallery.constants';

export const GALLERY_ROUTE_LIST: Routes = [
	{
		path: GALLERY_PATH,
		component: GalleryWrapperComponent,
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: GALLERY_ROUTE_NAMES.list.path,
			},
			{
				path: GALLERY_ROUTE_NAMES.list.path,
				title: GALLERY_ROUTE_NAMES.list.title,
				component: GalleryListComponent,
				canActivate: [AuthGuard],
				resolve: { CartResolver, DiscountsResolver },
			},
		],
	},
];
