import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbAccordionModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { effect, QueryList, Component, ViewChildren, AfterViewInit, OnInit } from '@angular/core';

import {
	ModalityStore,
	CartCountStore,
	SalesTeamStore,
	SideFilterStore,
	SalesPeriodStore,
	GalleryRequestStore,
	GalleryResponseStore,
	EbookImportStore,
} from '@lib-core/stores';
import { GALLERY_DEFAULT_PAGE, BOOTSTRAP_BREAKPOINT_SM, GALLERY_DEFAULT_PAGE_SIZE } from '@lib-core/constants';
import { CapitalizePipe } from '@lib-core/pipes';
import { GalleryEbookComponent } from '@proj-b2b/app/modules/gallery/components/gallery-ebook/gallery-ebook.component';
import { GalleryFilterGroupComponent } from '@proj-b2b/app/modules/gallery/components/gallery-filter-group/gallery-filter-group.component';
import { GalleryFilterViewerComponent } from '@proj-b2b/app/modules/gallery/components/gallery-filter-viewer/gallery-filter-viewer.component';
import { BaseEntityModel, SalesTeamModel, SideFilterModel } from '@lib-core/models';
import { GalleryRequestDto, GalleryResponseDto } from '@lib-core/dtos';

@Component({
	standalone: true,
	selector: '[app-gallery-filter]',
	imports: [
		FormsModule,
		CommonModule,
		CapitalizePipe,
		NgbTooltipModule,
		NgbAccordionModule,
		GalleryEbookComponent,
		GalleryFilterGroupComponent,
		GalleryFilterViewerComponent,
	],
	templateUrl: './gallery-filter.component.html',
})
export class GalleryFilterComponent implements OnInit, AfterViewInit {
	filterViewerCount = 0;
	isFilterCollapsed: boolean = false;

	filter: SideFilterModel = null;
	request: GalleryRequestDto = null;
	response: GalleryResponseDto = null;
	ebookCollapsed: boolean = true;

	modalityList = this.modalityStore.getList();
	salesTeamList: SalesTeamModel[];
	salesPeriodList = this.salesPeriodStore.getList();

	@ViewChildren(GalleryFilterGroupComponent)
	filterCheckboxList: QueryList<GalleryFilterGroupComponent>;

	constructor(
		private modalityStore: ModalityStore,
		private cartCountStore: CartCountStore,
		private salesTeamStore: SalesTeamStore,
		private sideFilterStore: SideFilterStore,
		private ebookImportStore: EbookImportStore,
		private salesPeriodStore: SalesPeriodStore,
		private galleryRequestStore: GalleryRequestStore,
		private galleryResponseStore: GalleryResponseStore,
	) {
		effect(() => {
			this.request = this.galleryRequestStore.get();
		});

		effect(() => {
			this.response = this.galleryResponseStore.get();
		});

		effect(() => {
			this.filter = this.sideFilterStore.get();
			this.removeInvalidColors();
		});

		effect(() => {
			this.salesTeamList = this.salesTeamStore.getList();
		});
	}

	ngOnInit(): void {
		this.checkHasEbookToImport();
	}

	ngAfterViewInit() {
		this.updateCollapsedState();
	}

	get isPageReady() {
		return this.response && this.request;
	}

	get sizeList(): BaseEntityModel[] {
		return this.filter?.sizes.map(size => {
			return { code: size.size, description: size.size } as BaseEntityModel;
		});
	}

	get hasProducts() {
		return this.response?.products?.length > 0;
	}

	get hasCart() {
		return this.cartCountStore.get() > 0;
	}

	get hasCartTooltip() {
		return this.hasCart ? 'Filtro desabilitado pois sua sacola já contém produtos' : '';
	}

	get shouldEnableSalesPeriod() {
		return !this.salesPeriodStore.isEmpty() && this.modalityStore.isProgramado();
	}

	checkHasEbookToImport(): void {
		if (this.ebookImportStore.get()) {
			this.ebookCollapsed = false;
		}
	}

	updateCollapsedState() {
		this.isFilterCollapsed = window.innerWidth < BOOTSTRAP_BREAKPOINT_SM;
	}

	updateRequest() {
		this.galleryRequestStore.set(this.request);
	}

	updateFilterViewerCount(count: number) {
		this.filterViewerCount = count;
	}

	clear() {
		this.clearCheckboxList();
		this.clearListFilters();
		this.updateRequest();
	}

	removeInvalidColors() {
		if (this.filter?.colors) {
			this.filter.colors = this.filter.colors?.filter(color => color?.rgb);
		}
	}

	clearCheckboxList() {
		this.filterCheckboxList.forEach(c => c.clear());
	}

	clearListFilters() {
		if (this.request == null) {
			return;
		}

		this.request.sizes = [];
		this.request.brands = [];
		this.request.colors = [];
		this.request.genders = [];
		this.request.species = [];
		this.request.seasons = [];
		this.request.segments = [];
		this.request.categories = [];
		this.request.collections = [];
		this.request.productGroups = [];
		this.request.page = GALLERY_DEFAULT_PAGE;
		this.request.pageSize = GALLERY_DEFAULT_PAGE_SIZE;
	}

	onFilterGroupChange(propName: string, values: BaseEntityModel[]) {
		this.request[propName] = values.map(item => item.code);
		this.request.page = GALLERY_DEFAULT_PAGE;
		this.request.pageSize = GALLERY_DEFAULT_PAGE_SIZE;
		this.updateRequest();
	}
}
