import { Component } from '@angular/core';
import { ScrollToTopButton } from '@lib-core/components/button-container-edge/buttons/scroll-to-top/scroll-to-top';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'app-button-container-edge',
	standalone: true,
	templateUrl: './button-container-edge.html',
	imports: [CommonModule, ScrollToTopButton],
})
export class ButtonContainerEdgeComponent {}
