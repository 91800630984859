import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SidebarService } from '@lib-core/services';
import { GalleryFilterComponent } from '@proj-b2b/app/modules/gallery/components/gallery-filter/gallery-filter.component';

@Component({
	standalone: true,
	selector: 'app-gallery-content',
	imports: [RouterOutlet, CommonModule, GalleryFilterComponent],
	templateUrl: './gallery-content.component.html',
})
export class GalleryContentComponent {
	constructor(private sidebarService: SidebarService) {}

	get useMenuOnSidebar(): boolean {
		return this.sidebarService.useMenuOnSidebar;
	}
}
