import { map, tap } from 'rxjs';
import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { CartService } from '@lib-core/services';
import { DiscountStore } from '@lib-core/stores';

export const DiscountsResolver: ResolveFn<boolean> = () => {
	const store = inject(DiscountStore);

	return inject(CartService)
		.getCommercialRules()
		.pipe(
			tap(data => {
				if (data.success) {
					store.setList(data.data);
					return;
				}
				store.clear();
			}),
		)
		.pipe(map(() => true));
};
