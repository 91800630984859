import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResource } from '@lib-core/resources';
import { ApiResponseModel, AppliedDiscountsModel, CartModel } from '@lib-core/models';
import { CartCommercialRulesRequestDto, CartRequestDto } from '@lib-core/dtos';

@Injectable({
	providedIn: 'root',
})
export class CartResource {
	private baseUrl = 'Cart';

	constructor(private httpResource: HttpResource) {}

	getCart(request: CartRequestDto): Observable<ApiResponseModel<CartModel>> {
		return this.httpResource.post<ApiResponseModel<CartModel>>(`${this.baseUrl}/GetCart`, request, {
			showLoader: true,
			loaderMessage: request.loadProductDetails ? 'Carregando produtos da sua sacola' : 'Carregando sua sacola',
		});
	}

	deleteCart(request: CartRequestDto): Observable<ApiResponseModel<boolean>> {
		return this.httpResource.delete<ApiResponseModel<boolean>>(`${this.baseUrl}/DeleteCart`, request);
	}

	addItems(request: CartRequestDto): Observable<ApiResponseModel<CartModel>> {
		return this.httpResource.post<ApiResponseModel<CartModel>>(`${this.baseUrl}/AddItem`, request, {
			showLoader: true,
			loaderMessage: 'Adicionando produto(s) a sua sacola',
		});
	}

	removeItems(request: CartRequestDto): Observable<ApiResponseModel<CartModel>> {
		return this.httpResource.delete<ApiResponseModel<CartModel>>(`${this.baseUrl}/RemoveItem`, request, {
			showLoader: true,
			loaderMessage: 'Removendo produto(s) da sacola',
		});
	}

	clearItems(request: CartRequestDto): Observable<ApiResponseModel<CartModel>> {
		return this.httpResource.delete<ApiResponseModel<CartModel>>(`${this.baseUrl}/ClearItem`, request, {
			showLoader: true,
			loaderMessage: 'Limpando sua sacola',
		});
	}

	getCommercialRules(request: CartRequestDto): Observable<ApiResponseModel<AppliedDiscountsModel[]>> {
		return this.httpResource.post<ApiResponseModel<AppliedDiscountsModel[]>>(`${this.baseUrl}/GetCommercialRules`, request);
	}

	updateCommercialRules(request: CartCommercialRulesRequestDto): Observable<ApiResponseModel<boolean>> {
		return this.httpResource.put<ApiResponseModel<boolean>>(`${this.baseUrl}/UpdateCommercialRules`, request, {
			showLoader: true,
			loaderMessage: 'Atualizando regras comerciais',
		});
	}

	processCart(cartId: string) {
		return this.httpResource.get<ApiResponseModel<CartModel>>(`${this.baseUrl}/ProcessCart/${cartId}`, {
			showLoader: true,
			loaderMessage: 'Calculando seus descontos',
		});
	}

	finishCart(cartId: string) {
		return this.httpResource.get<ApiResponseModel<CartModel>>(`${this.baseUrl}/FinishCart/${cartId}`, {
			showLoader: true,
			loaderMessage: 'Finalizando seu pedido, por favor não atualize a página',
		});
	}
}
